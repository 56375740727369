@import "../../variables";

.criterion-arrow {
    cursor: pointer;
    
    .arrow-end {
        position: absolute;
        margin-top: 0.5rem;
        margin-left: 8px;
        width: 0;
        height: 0;
        border-top: 2.45em solid transparent;
        border-bottom: 2.45em solid transparent;
        z-index: 100;
        border-left: 1.4em solid $color-turquoise;
    }
    
    .arrow-end-triangle {
        position: absolute;
        margin-top: 0.5rem;
        width: 0;
        height: 0;
        border-top: 39px solid transparent;
        border-bottom: 39px solid transparent;
        z-index: 1000;
        border-left: 1.4em solid #EDEDEE;
    }
    
    .pointer {
        position: relative;
        background: $color-white;
        margin-top: 0.5rem;
        width: 100%;
        padding: 8px;
        border-left: 8px solid $color-turquoise;
        overflow: hidden;
        font-size: 14px;
    
        span {
            background: $color-white;
            word-wrap: break-word;
        }
    }
    
    .pointer-end {
        position: relative;
        margin-top: 0.5rem;
        width: 0;
        height: 0;
        border-top: 2.45em solid transparent;
        border-bottom: 2.45em solid transparent;
        z-index: 100;
        border-left: 1.4em solid $color-white;
    }

    &.-in {
        .pointer {
            border-right: 8px solid $color-dark-blue;
            border-left: none;
        };

        .pointer-end {
            border-right: 1.4em solid $color-white;
            border-left: none;
        }

        .arrow-end-triangle {
            border-right: 1.4em solid #EDEDEE;
            border-left: none;
        }

        .arrow-end {
            border-right: 1.4em solid $color-dark-blue;
            border-left: none;
            right: 23px;
            margin-left: 0;

        }
    }

    &:first-of-type {
        .arrow-end {
            margin-top: 0;
        }

        .arrow-end-triangle {
            margin-top: 0;
        }

        .pointer {
            margin-top: 0;
        }

        .pointer-end {
            margin-top: 0;
        }
    }
}
@import "./variables.scss";
@import "./utils.scss";

@font-face {
    font-family: 'Geomanist';
    src: url("./fonts/Geomanist/Geomanist/Geomanist-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Geomanist-bold';
    src: url("./fonts/Geomanist/Geomanist/Geomanist-Bold.otf") format("opentype");
}

body {
    font-family: $ff-regular;
}

h1,h2,h3,h4,h5{
    margin-bottom:0;
}

strong{
    font-weight: $fw-bold;
    font-family: $ff-bold;
}

@mixin _tg-title{
    font-family: $ff-bold;
    font-weight: $fw-bold;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 1.5px;
    color:$color-dark-blue;
    margin-bottom: 1.5rem;
    display: block;
}

.-tg-title{
    @include _tg-title;
}

@mixin _tg-title-uppercase{
    font-weight: $fw-bold;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:$color-black;

    @include md {
        max-width: 100%;
        font-size: 50px;
        line-height: 46px;
    }
}

.-tg-title-uppercase{
    @include _tg-title-uppercase;
}

@mixin _tg-small-title {
    font-family: $ff-regular;
    font-weight: $fw-bold;
    font-size: 20px;
    line-height: 22px;
    color:$color-dark-grey;
    display:block;
    margin-bottom: 0.5rem;
}

.-tg-small-title {
    @include _tg-small-title;

    &.-bold {
        font-family: $ff-bold;
    }

    &.-uppercase {
        text-transform: uppercase;
    }

    &.-higher {
        padding: 15px 0;
    }
}

@mixin _tg-subtitle{
    font-family: $ff-bold;
    color: $color-dark-grey;
    font-weight: $fw-thin;
    font-size: 30px;
    line-height: 25px;
    letter-spacing: 1px;
    // text-transform: uppercase;
}
.-tg-subtitle{
    @include _tg-subtitle;

    &.-bigger {
        font-size: 40px;
    }

    &.-large {
        font-size: 50px;
    }
}

@mixin _tg-small-subtitle{
    font-family: $ff-bold;
    color: $color-black;
    font-weight: $fw-thin;
    font-size: 20px;
    line-height: 25px;
    display: block;
    // letter-spacing: 1px;
    // text-transform: uppercase;
}
.-tg-small-subtitle{
    @include _tg-small-subtitle;

    &.-smaller {
        font-size: 16px;
    }

    &.-bigger {
        font-size: 40px;
    }

    &.-large {
        font-size: 50px;
    }
}

@mixin _tg-reg-text{
    font-family: $ff-regular;
    color: $color-black;
    font-weight: $fw-regular;
    font-size: 14px;
    line-height: 25px;
    display: block;
}
.-tg-reg-text{
    @include _tg-reg-text;

    &.-bold {
        font-family: $ff-bold;
    }

    &.-small {
        font-size: 12px;
    }

    &.-lh-small {
        line-height: 15px;
    }
}

@mixin _tg-link{
    font-family: $ff-regular;
    color: $color-black;
    font-weight: $fw-regular;
    font-size: 14px;
    display: block;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: $color-dark-grey;
    }
}
.-tg-link{
    @include _tg-link;

    &.-turq {
        color: $color-turquoise;
        font-weight: $fw-extrablack;

        &:hover {
            color: $color-dark-blue;
        }
    }
}
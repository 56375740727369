@import "../../../variables.scss";
@import "../../../utils.scss";

.level-view {
    .level-navbar {
        background: $color-dark-blue;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        // top: 56px;
        z-index: 10000;
        padding: 15px 0px;
        
        @include xl {
            padding: 15px 50px;
        }
        
        .navbar-item {
            .nav-link {
                color: $color-white;

                &.home-link {
                    overflow: visible;
                    white-space: nowrap;
                }
            }
        }
    
        .level-navbar-item {
            margin-left: 20px;
            
            &:first-child {
                margin-left: 0px;
            }
    
            button {
                background: $color-white;
                color: $color-dark-blue;
                padding: 12px;
                border-radius: 15px;
    
                &:hover {
                    background: $color-turquoise;
                    color: $color-white;
                }
    
                &:focus {
                    background: $color-turquoise;
                    color: $color-white;
                    box-shadow: none;
                }
    
                &:active {
                    background: $color-turquoise;
                    color: $color-white;
                }
    
                &.active {
                    background: $color-turquoise;
                    color: $color-white;
                }
    
            }
        }
    }
}
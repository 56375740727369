form {
    input, textarea {
        margin: 10px 0;
    }

    input[type=checkbox] {
        margin-right: 10px;
    }

    .form-title {
        font-weight: 800;
    }
}

.form-field {
    display: flex;
    flex-flow: column-reverse;

    label, input, select, textarea {
        transition: all 0.2s;
        touch-action: manipulation;
    }

    select {
        margin: 10px 0;
        color: darkgrey;
    }

    >label {
        margin-bottom: -8px;
        font-size: 11px;
        padding-left: .5rem;
        color: darkgrey;

        &.select-label {
            padding-left: 0;
        }
    }

    input:placeholder-shown + label, textarea:placeholder-shown + label {
        cursor: text;
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125rem) scale(1.5);
    }

    ::-webkit-input-placeholder {
        opacity: 0;
        color: white;
        transition: inherit;
    }

    input:focus::-webkit-input-placeholder {
        opacity: 1;
    }

    input:not(:placeholder-shown) + label,
    input:focus + label, textarea:not(:placeholder-shown) + label,
    textarea:focus + label, select:not(:placeholder-shown) + label,
    select:focus + label {
        padding-left: 0px;
        transform: translate(0, 0) scale(1);
        cursor: pointer;
    }
}
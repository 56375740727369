@import "../../variables";

.frontend-list-item {
    border-radius: 6px;
    border-left: 8px solid;
    border-right: none;
    border-top: none;
    border-bottom: none;
}

.criterion-list-item {
    .col {
        &.-with-border {
            
            .border {
                display: block;
                margin-top: 10px;
            }
        }
    }


    &:last-of-type {
        .col {
            &.-with-border {
                margin-bottom: 0;
                border-top: none;
                padding-top: 0;

                .border {
                    display: none;
                }
            }
        }
    }
}

.arrow-col {
    background: $color-light-grey;
    // margin-right: -16px;
}
// Body
$body-bg: #f8fafc;

// Typography
$ff-regular: 'Geomanist';
$ff-bold: 'Geomanist-bold';
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$fw-extralight  :   100; 
$fw-ultralight  : 	$fw-extralight;
$fw-light 		:	200;
$fw-thin 		:	$fw-light;
$fw-book 		:	300;
$fw-demi 		: 	$fw-book;
$fw-normal 	    :	400;
$fw-regular 	: 	$fw-normal;
$fw-medium 	    : 	500;
$fw-semibold 	: 	600;
$fw-demibold 	: 	$fw-semibold;
$fw-bold 		: 	700;
$fw-extrabold 	:	800;
$fw-heavy 		:	$fw-extrabold;
$fw-extrablack  :	900;
$fw-fat 		:	$fw-extrablack; 
$fw-ultrablack  : 	$fw-extrablack;

// Colors
$color-light-grey: #EDEDEE;
$color-dark-grey: #133D50;
$color-turquoise: #107D8F;
$color-dark-blue: #133D50;
$color-blue: #0297fa;
$color-white: #FFFFFF;
$color-black: black;

@import "../variables";

.navbar {
    background: $color-light-grey;
    position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	padding: 0;


	&.-blue {
		background: $color-dark-blue;
		.navbar-item {
			>.nav-link {
				color: $color-white;
			}
		}
	}

	.navbar-item {
		>.nav-link {
			color: $color-dark-blue;
			padding: 1rem 1rem;

			p {
				margin: 0;
			}

			&.active {
				color: $color-dark-blue;
				background: #EDEDEE;

				&.-blue {
					color: $color-white;
					background: $color-dark-blue;
				}
			}

			&.home-link {
				overflow: visible;
				white-space: nowrap;
			}
		}
	}
}
@import "../../../variables.scss";
@import "../../../utils.scss";

.theme-view {
    .theme-navbar {
        background: $color-turquoise;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        // top: 56px;
        z-index: 10000;
        padding: 0 0px;
        margin-top: 80px;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 8px solid;
        
        @include xl {
            padding: 0 50px;
        }
        
        .navbar-item {
            .nav-link {
                color: $color-white;
            }
        }
    
        .theme-navbar-item {
            margin-left: 0;
            
            &:first-child {
                margin-left: 0px;
            }
    
            button {
                background: $color-turquoise;
                color: $color-white;
                padding: 27px;
                border-radius: 0;
                border: none;

                &:not(.active) {
                    >span {
                        border-bottom: 2px solid;
                        border-color: $color-turquoise!important;
                    }
                }
    
                &:hover {
                    background: $color-turquoise;
                    color: $color-white;
                    
                    >span {
                        border-bottom: 2px solid;
                    }
                }
    
                &:focus {
                    background: $color-turquoise;
                    color: $color-white;
                    box-shadow: none;
                }
    
                &:active {
                    background: $color-turquoise;
                    color: $color-white;
                }
    
                &.active {
                    background: $color-turquoise;
                    color: $color-white;

                    >span {
                        border-bottom: 2px solid;
                    }
                }
    
            }
        }
    }
}
@import "../../variables";
@import "../../utils";

.highlight {
    color: $color-turquoise;
    cursor: pointer;
}

.arrow:after {
    border-bottom-color: $color-turquoise!important;
}

.popover, .popover-content, .popover-header, .popover-title, .popover-body {
    background: $color-turquoise;
    color: $color-white;
    border-color: $color-turquoise;
}

.popover {
    max-width: 75vw;

    @include lg {
        max-width: 50vw;
    }
}